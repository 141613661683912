import axios from 'axios';
import device from "current-device";


// 创建新的axios实例
const service = axios.create({
	// 环境变量，需要在.env文件中配置
	baseURL: process.env.NODE_ENV === "production" ? process.env.VUE_APP_API_BASE_URL : "https://tg.slmtools.top/",
	// 超时时间暂定5s
	timeout: 60000,
});


// request.js
service.interceptors.request.use(
	config => {
		
		if (config.method === 'post') {
			config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
		}
		config.headers['api-type'] = device.type;
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);


// request.js
service.interceptors.response.use(
	response => {
		// 关闭loading
		/*if (response.data.code === 401){
			layer.confirm(
				'会话超时, 请重新登录',
				{ icon : 2, yes: function(){
						router.push('/login', { replace: true });
						layer.closeAll()
					}});
		}*/
		return response;
	},
	error => {
		// 处理异常情况，根据项目实际情况处理或不处理
		if (error && error.response) {
			// 根据约定的响应码处理
			switch (error.response.status) {
				case 403:
					error.message = '拒绝访问';
					break;
				case 502:
					error.message = '服务器端出错';
					break;
				default:
					error.message = `连接错误${error.response.status}`;
			}
		} else {
			// 超时处理
			error.message = '服务器响应超时，请刷新当前页';
		}

		//showToast(error.message);
		return Promise.resolve(error.response);
	}
);


const Request = (url, options = {}) => {
	let method = options.method || 'get';
	let params = options.params || {};
	
	if (method === 'get' || method === 'GET') {
		return new Promise((resolve, reject) => {
			service
				.get(url, {
					params: params,
				})
				.then(res => {
					if (res && res.data) {
						resolve(res.data);
					}
				})
				.catch(err => {
					reject(err);
				});
		});
	} else {
		return new Promise((resolve, reject) => {
			service
				.post(url, params)
				.then(res => {
					if (res && res.data) {
						resolve(res.data);
					}
				})
				.catch(err => {
					reject(err);
				});
		});
	}
};

// request.js
export default Request;


