import Http from '../http';

export function apiBasicInfo() {
	return Http('/api/web/basis');
}

export function apiLogin(params) {
	return Http('/api/web/login', {
		method: 'POST',
		params: params,
	});
}
